/** @jsx jsx */
/**
 *
 * FreshPets Hero
 *
 */

import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { Box, Container, Flex, Heading, jsx, Text, Paragraph } from 'theme-ui'
import BackgroundImage from 'gatsby-background-image'
import Link from '../link/link'

const Hero = () => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "oliver.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        transformOptions: {
                            duotone: {
                                highlight: "#00A29E"
                                shadow: "#192550"
                                opacity: 80
                            }
                        }
                        formats: [AUTO, WEBP]
                    )
                }
            }
        }
    `)

    // FIXME: Brdige for using new gatsbyImageData with BackgroundImage
    const { placeholderImage = {} } = data
    const heroImage = getImage( placeholderImage )
    const heroBackgroundImage = convertToBgImage(heroImage)

    return (
        <BackgroundImage
            Tag='section'
            {...heroBackgroundImage}
            sx={{
                width: '100vw'
            }}
            // fluid={gatsbyImageData}
            backgroundColor='#00A29E'>
            <Flex
                sx={{
                    flexDirection: 'column',
                    width: '100vw',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Container>
                    <Box sx={{ width: ['100%', '75%'], px: [3, 4, 5] }}>
                        <Heading
                            sx={{
                                mt: [4, 0],
                                mb: 4,
                                fontSize: [6, 7, 8],
                                color: 'white'
                            }}
                            as='h1'>
                            We Have The Freshest Pets
                        </Heading>
                        <Paragraph
                            sx={{
                                marginBottom: 3,
                                color: 'white',
                                fontSize: [3, 4, 5]
                            }}>
                            Fresh Pets is a professional salon dedicated to
                            providing a high quality service for all your
                            grooming needs.
                        </Paragraph>
                        <Paragraph
                            sx={{
                                mb: 3,
                                color: 'white',
                                fontSize: [3, 4, 5]
                            }}>
                            From Clipping services to Ultrasonic Teeth Cleaning,
                            we’ve got you covered.
                        </Paragraph>
                        <Link
                            to={process.env.GATSBY_FACEBOOK_MESSENGER_URL}
                            rel='noopener noreferrer'
                            target='_blank'
                            sx={{
                                variant: 'links.button.primary',
                                bg: 'primary_light',
                                fontSize: 4
                            }}>
                            Make a Booking{' '}
                            <span role='img' aria-label='dog face emoji'>
                                🐶
                            </span>
                        </Link>
                    </Box>
                </Container>
            </Flex>
        </BackgroundImage>
    )
}

export default Hero
