/** @jsx jsx */
/**
 *
 * FreshPets Index Page
 *
 */
import { Flex, Heading, jsx } from 'theme-ui'
import FreshPetsLogo from '../assets/images/fresh-pets-json-ld-logo.png'
import Hero from '../components/hero/hero'
import InstagramGrid from '../components/instagram-grid/instagram-grid'
import JsonLd from '../components/json-ld/json-ld'
import Layout from '../components/layout/layout'
import Link from '../components/link/link'
import MeetTheTeamGrid from '../components/meet-the-team-grid/meet-the-team-grid'
import Seo from '../components/seo/seo'
import ServicesGrid from '../components/services-grid/services-grid'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const IndexPage = () => {
    const { title = '', siteUrl = '' } = useSiteMetadata()

    return (
        <Layout>
            <Seo title='Home' />
            <JsonLd>
                {{
                    '@context': 'https://schema.org',
                    '@type': 'LocalBusiness',
                    '@id': 'https://www.fresh-pets.co.uk/#organization',
                    url: siteUrl,
                    name: title,
                    logo: FreshPetsLogo,
                    image: FreshPetsLogo,
                    telephone: '+447801255796',
                    address: {
                        '@type': 'PostalAddress',
                        streetAddress: '3 Saint James\' Street',
                        addressLocality: 'Newport',
                        postalCode: 'PO30 5HE',
                        addressCountry: 'GB'
                    },
                    openingHoursSpecification: {
                        '@type': 'OpeningHoursSpecification',
                        dayOfWeek: [
                            'Monday',
                            'Tuesday',
                            'Wednesday',
                            'Thursday',
                            'Saturday'
                        ],
                        opens: '09:00',
                        closes: '17:00'
                    },
                    sameAs: [
                        process.env.GATSBY_FACEBOOK_URL,
                        process.env.GATSBY_INSTAGRAM_URL
                    ]
                }}
            </JsonLd>
            <Hero />
            <ServicesGrid />
            <Flex
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    px: 3,
                    py: 4,
                    my: 3
                }}>
                <Heading as='h2' sx={{ fontSize: [ 5, 6, 7 ], mb: 1 }}>
                    Doggo Gallery
                </Heading>
                <Heading
                    as='h3'
                    sx={{ fontSize: [ 4, 5 ], mb: 3, color: 'secondary' }}>
                    Ready for a night on the town
                </Heading>
            </Flex>
            <InstagramGrid slice={3} />
            <Flex sx={{ px: 3, my: 5, justifyContent: 'center' }}>
                <Link
                    sx={{
                        variant: 'links.button.primary',
                        maxWidth: '50%',
                        fontSize: 4
                    }}
                    to='/gallery/'>
                    View Our Work
                </Link>
            </Flex>
            <Flex
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    px: 3,
                    py: 4,
                    my: 3
                }}>
                <Heading as='h2' sx={{ fontSize: [ 5, 6, 7 ], mb: 1 }}>
                    Meet The Team
                </Heading>
                <Heading as='h3' sx={{ fontSize: [ 4, 5 ], color: 'secondary' }}>
                    Friendly, knowledgeable staff for all your canine needs
                </Heading>
            </Flex>
            <MeetTheTeamGrid />
            <Flex sx={{ px: 3, my: 5, justifyContent: 'center' }}>
                <Link
                    sx={{
                        variant: 'links.button.primary',
                        maxWidth: '50%',
                        fontSize: 4
                    }}
                    to='/team/'>
                    Say hi to The Fresh Pets Team
                </Link>
            </Flex>
        </Layout>
    )
}

export default IndexPage
