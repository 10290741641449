/** @jsx jsx */
/**
 *
 * FreshPets Services Grid Item
 *
 */
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'

const ServicesGridItem = ({ icon, title, description }) => (
    <Flex sx={{ mb: 3 }}>
        <Box
            sx={{
                p: 3,
                alignSelf: 'center',
                mr: [2, 5]
            }}>
            <Box as='span' className='fa-layers fa-fw' sx={{ fontSize: 7 }}>
                <FontAwesomeIcon
                    fixedWidth
                    icon={faCircle}
                    sx={{ color: 'secondary', opacity: 0.3 }}
                    transform='grow-4'
                />
                <FontAwesomeIcon
                    fixedWidth
                    icon={icon}
                    sx={{ color: 'primary' }}
                    transform='shrink-6'
                />
            </Box>
        </Box>
        <Flex sx={{ flexDirection: 'column', p: 3 }}>
            <Heading sx={{ mb: 2, fontSize: 4 }}>{title}</Heading>
            <Text as='p' sx={{ mb: 2, fontSize: 3 }}>
                {description}
            </Text>
        </Flex>
    </Flex>
)

ServicesGridItem.propTypes = {
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default ServicesGridItem
