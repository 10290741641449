/** @jsx jsx */
/**
 *
 * FreshPets Services Grid
 *
 */
import {
    faBath, faCut,
    faDog,
    faHandHeart,
    faToothbrush,
    faWatch
} from '@fortawesome/pro-duotone-svg-icons'
import React from 'react'
import { Box, Container, Flex, Heading, jsx } from 'theme-ui'
import Link from '../link/link'
import ServicesGridItem from './services-grid-item'

const ServicesGrid = () => (
    <React.Fragment>
        <Flex
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                px: 3,
                py: 4,
                my: 3
            }}>
            <Heading as='h2' sx={{ fontSize: [ 5, 6, 7 ], mb: 1 }}>
                Top to Tail Services
            </Heading>
            <Heading
                as='h3'
                sx={{ fontSize: [ 4, 5 ], mb: 3, color: 'secondary' }}>
                Professional dog grooming since 2005
            </Heading>
        </Flex>
        <Container>
            <Flex sx={{ flexWrap: 'wrap', px: -3 }}>
                <Box
                    sx={{
                        width: [ '100%', '50%', '33.3%' ]
                    }}>
                    <ServicesGridItem
                        icon={faHandHeart}
                        title='Hand Stripping'
                        description='We specialise in hand stripping, masters of achieving the correct coat appearance' />
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%', '33.3%' ]
                    }}>
                    <ServicesGridItem
                        icon={faCut}
                        title='Clipping/Trimming'
                        description='Hand-scissor trim, a complete all-over clip off or something in between' />
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%', '33.3%' ]
                    }}>
                    <ServicesGridItem
                        icon={faBath}
                        title='Bath & Brush'
                        description='Freshen up with a scrub in the tub & a brush through or light undercoat removal' />
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%', '33.3%' ]
                    }}>
                    <ServicesGridItem
                        icon={faToothbrush}
                        title='Teeth Cleaning'
                        description='Ultrasonic cleaning using the Mira-Pet system to achieve healthier oral hygiene' />
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%', '33.3%' ]
                    }}>
                    <ServicesGridItem
                        icon={faDog}
                        title='Puppy Grooms'
                        description='A positive grooming experience at puppy age prepares them for a lifetime of grooming' />
                </Box>
                <Box
                    sx={{
                        width: [ '100%', '50%', '33.3%' ]
                    }}>
                    <ServicesGridItem
                        icon={faWatch}
                        title='While You Wait'
                        description='No pre booked appointment needed however, calling ahead will reduce waiting time' />
                </Box>
                
            </Flex>
            <Flex sx={{ px: 3, my: 5, justifyContent: 'center' }}>
                <Link
                    sx={{
                        variant: 'links.button.primary',
                        maxWidth: '50%',
                        fontSize: 4
                    }}
                    to='/services/'>
                    View Our Services
                </Link>
            </Flex>
        </Container>
    </React.Fragment>
)

export default ServicesGrid
