/**
 * Fresh Pets Structured Data
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const JsonLd = ({ children }) => (
    <Helmet>
        <script type='application/ld+json'>{JSON.stringify(children)}</script>
    </Helmet>
)

JsonLd.propTypes = {
    children: PropTypes.object.isRequired
}

export default JsonLd
